import React from "react";
import { RouteObject } from "react-router-dom";

const Home = React.lazy(() => import("@/pages/home"));
const News = React.lazy(() => import("@/pages/news"));
const Solutions = React.lazy(() => import("@/pages/solutions"));
const Social = React.lazy(() => import("@/pages/social"));
const About = React.lazy(() => import("@/pages/about"));
const Jobs = React.lazy(() => import("@/pages/jobs"));
const NotFound = React.lazy(() => import("@/pages/not-found"));

/**
 * 路由配置
 */
const routes: RouteObject[] = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/news",
    element: <News />,
  },
  {
    path: "/solutions",
    element: <Solutions />,
  },
  {
    path: "/social",
    element: <Social />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/jobs",
    element: <Jobs />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export default routes;

import React from "react";
import { useRoutes } from "react-router-dom";

import routes from "./router";
import { usePageChange } from "./hooks/usePageChange";

function App() {
  usePageChange();
  return <React.Fragment>{useRoutes(routes)}</React.Fragment>;
}

export default App;

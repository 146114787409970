import { configureStore } from "@reduxjs/toolkit";
import MainReducer from "./modules/main";

const store = configureStore({
  reducer: {
    main: MainReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>
export default store;

import { IFooterConfig } from "@/types";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

/**
 * @description 整个项目公共共享的数据
 * @param headerConfig header 配置
 * @param footerConfig footer 配置
 */
type SliceState = {
  footerConfig: IFooterConfig;
};
const initialState: SliceState = {
  footerConfig: {
    theme: "light",
  },
};
const MainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    changeFooterConfigAction: (state, action: PayloadAction<IFooterConfig>) => {
      state.footerConfig = action.payload;
    },
  },
});

export const { changeFooterConfigAction } = MainSlice.actions;
export default MainSlice.reducer;

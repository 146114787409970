import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
// support for lazyload
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";

import store from "./store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "@/assets/css/index.less";
import Loading from "./components/Loading";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* TODO: 自定义 loading */}
      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Suspense>
    </Provider>
  </React.StrictMode>
);
reportWebVitals();
